<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        temporary
        v-model="setting_dialog"
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-row>
                <v-subheader class="success--text" style="border-bottom: 1px solid var(--v-success-base); width: 100%; height: 40px; padding-top: 6px">
                  Налаштування друку
                </v-subheader>
                <v-col cols="12" md="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_differance_page"
                            label="2 копії на різні сторінки"
                            class="mt-0"
                            @change="duplicateOnDifferancePageChange"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4" style="height: auto !important; min-height: 215mm">
      <div class="document-print-form">
        <template v-if="document_setting.duplicate_on_differance_page">
          <template v-for="copy in 2">
            <div :key="`copy-${copy}`">
              <div class="document-table-form">
                <div style="display: flex;">
                  <div style="flex: 0 1 64%; margin-right: 4px; border-right: 1px dashed black; padding-right: 8px">
                    <div style="height: 64px; margin-bottom: 10px"></div>
                    <div style="padding-left: 20px; padding-right: 30px;">
                      Iдентифiкацiйний код в Єдиному державному реєстрі підприємств та
                    </div>
                    <div style="padding-left: 20px; padding-right: 30px">
                      організацій України
                    </div>
                    <div style="padding-left: 20px; padding-right: 30px;">
                      <div style="border-bottom: 1px solid; padding-top: 10px; text-align: center; font-size: 14px">
                        {{ document_data.organization_code }}
                      </div>
                      <div style="border-bottom: 1px solid; padding-top: 10px; text-align: center; font-size: 14px">
                        {{ document_data.organization_short_name }}
                      </div>
                      <div style="text-align: center; font-size: 11px">
                        (найменування підприємства/установи/організації)
                      </div>
                    </div>
                    <div style="font-size: 22px; font-weight: bold; text-align: center; margin-top: 4px; margin-bottom: 4px">
                      Прибутковий касовий ордер № {{ document_data.order_number }}
                    </div>
                    <div style="text-align: center">від {{ formatDate(document_data.doc_date, 'DD MMMM YYYY') }} року</div>
                  </div>
                  <div style="flex: 1; margin-left: 4px">
                    <div style="display: flex; margin-bottom: 10px">
                      <div style="flex: 0 1 27%"></div>
                      <div style="flex: 1; line-height: 16px">
                        Додаток 2 <br>
                        до Положення про ведення <br>
                        касових операцій у національній <br>
                        валюті в Україні <br>
                        (пункт 25 розділу III)
                      </div>
                    </div>
                    <div style="text-align: right">Типова форма № КО-1</div>
                    <div style="margin-top: 12px">
                      <div style="border-bottom: 1px solid; text-align: left; font-size: 13px">
                        {{ document_data.organization_short_name }}
                      </div>
                      <div style="text-align: center; font-size: 11px">
                        (найменування підприємства/установи/організації)
                      </div>
                    </div>
                    <div style="font-size: 22px; font-weight: bold; text-align: left; margin-top: 4px;">
                      Квитанція
                    </div>
                    <div>
                      до прибуткового касового <br>
                      ордеру № {{ document_data.order_number }} <br>
                      від {{ formatDate(document_data.doc_date, 'DD MMMM YYYY') }} року
                    </div>
                  </div>
                </div>
                <div style="display: flex;">
                  <div style="flex: 0 1 64%; margin-right: 4px; border-right: 1px dashed black; padding-right: 8px">
                    <div>
                      <table style="width: 100%">
                        <thead>
                        <tr>
                          <th style="width: 50px">№ з/п</th>
                          <th style="width: 113px">Кореспонду-<br>ючий рахунок, субрахунок</th>
                          <th style="width: 105px">Код аналі-<br>тичного рахунку</th>
                          <th style="width: 300px">Сума цифрами</th>
                          <th style="width: 80px">Код цільового призначення</th>
                          <th>Примітки</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>1</td>
                          <td>2</td>
                          <td>3</td>
                          <td>4</td>
                          <td>5</td>
                          <td>6</td>
                        </tr>
                        <tr style="font-weight: bold">
                          <td>1</td>
                          <td>{{ document_data.credit_code }}</td>
                          <td></td>
                          <td>{{ document_data.sum | formatToFixed | formatNumber }} грн</td>
                          <td></td>
                          <td></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style="flex: 1; margin-left: 4px; display: flex; flex-direction: column; justify-content: flex-end;">
                    <div style="font-weight: bold">
                      Прийнято від
                    </div>
                  </div>
                </div>
                <div style="display: flex;">
                  <div style="flex: 0 1 64%; margin-right: 4px; border-right: 1px dashed black; padding-right: 8px">
                    <div>
                      <div style="display: flex; margin-top: 2px">
                        <div style="flex: 0 0 110px; font-weight: bold; font-size: 13px">
                          Прийнято від
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black">
                          {{ document_data.get_from }}
                        </div>
                      </div>
                      <div style="height: 24px"></div>
                      <div style="display: flex;">
                        <div style="flex: 0 0 110px; font-weight: bold; font-size: 13px">
                          Підстава
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black">
                          {{ document_data.reason }}
                        </div>
                      </div>
                      <div style="display: flex; margin-top: 4px">
                        <div style="flex: 0 0 110px; font-weight: bold; font-size: 13px">
                          Сума
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black">
                          {{ document_data.converted_number }}
                        </div>
                      </div>
                      <div style="display: flex;">
                        <div style="flex: 0 0 110px; font-weight: bold; font-size: 13px">
                        </div>
                        <div style="flex: 1; text-align: center">
                          (словами)
                        </div>
                      </div>
                      <div style="display: flex; margin-top: 4px">
                        <div style="flex: 0 0 140px; font-weight: bold; font-size: 13px">
                          в тому числі ПДВ
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black">
                          0,00 грн
                        </div>
                      </div>
                      <div style="display: flex; margin-top: 4px">
                        <div style="flex: 0 0 72px; font-weight: bold; font-size: 13px">
                          додатки:
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black">
                          {{ document_data.additional }}
                        </div>
                      </div>
                      <div style="display: flex; margin-top: 15px">
                        <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px">
                          Головний бухгалтер
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black">
                          {{ chief_accountant }}
                        </div>
                      </div>
                      <div style="display: flex;">
                        <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px">
                        </div>
                        <div style="flex: 1; text-align: center">
                          (підпис, прізвище, ініціали)
                        </div>
                      </div>
                      <div style="display: flex; margin-top: 4px">
                        <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px">
                          Одержав касир
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black">
                        </div>
                      </div>
                      <div style="display: flex;">
                        <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px">
                        </div>
                        <div style="flex: 1; text-align: center">
                          (підпис, прізвище, ініціали)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1; margin-left: 4px">
                    <div style="border-bottom: 1px solid black; height: 21px; margin-top: 2px">
                      {{ document_data.get_from }}
                    </div>
                    <div style="margin-top: 5px; font-weight: bold">Підстава</div>
                    <div style="border-bottom: 1px solid black; height: 21px;">
                      {{ document_data.reason }}
                    </div>
                    <div style="display: flex; margin-top: 4px">
                      <div style="flex: 0 0 50px; font-weight: bold; font-size: 13px">
                        Сума
                      </div>
                      <div style="flex: 1; border-bottom: 1px solid black">
                        {{ document_data.converted_number }}
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="flex: 0 0 50px; font-weight: bold; font-size: 13px">
                      </div>
                      <div style="flex: 1; text-align: center">
                        (словами)
                      </div>
                    </div>
                    <div style="margin-top: 4px">В т.ч ПДВ: 0.00 грн.</div>
                    <div style="margin-top: 4px">М.П.</div>
                    <div style="display: flex; margin-top: 0">
                      <div style="flex: 0 0 80px; font-weight: bold; font-size: 12px">
                        Головний бухгалтер
                      </div>
                      <div style="flex: 1; border-bottom: 1px solid black; align-self: end">
                        {{ chief_accountant }}
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="flex: 0 0 80px; font-weight: bold; font-size: 13px">
                      </div>
                      <div style="flex: 1; text-align: center">
                        (підпис, прізвище, ініціали)
                      </div>
                    </div>
                    <div style="display: flex; margin-top: 4px">
                      <div style="flex: 0 0 80px; font-weight: bold; font-size: 12px">
                        Касир
                      </div>
                      <div style="flex: 1; border-bottom: 1px solid black">
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="flex: 0 0 80px; font-weight: bold; font-size: 12px">
                      </div>
                      <div style="flex: 1; text-align: center">
                        (підпис, прізвище, ініціали)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="copy === 1">
                <p class="document-separator" :key="`sep-${copy}`">&nbsp;</p>
              </template>
            </div>
          </template>
        </template>
        <template v-if="!document_setting.duplicate_on_differance_page && !document_setting.duplicate_on_one_page">
          <div class="document-table-form">
            <div style="display: flex;">
              <div style="flex: 0 1 64%; margin-right: 4px; border-right: 1px dashed black; padding-right: 8px">
                <div style="height: 64px; margin-bottom: 10px"></div>
                <div style="padding-left: 20px; padding-right: 30px;">
                  Iдентифiкацiйний код в Єдиному державному реєстрі підприємств та
                </div>
                <div style="padding-left: 20px; padding-right: 30px">
                  організацій України
                </div>
                <div style="padding-left: 20px; padding-right: 30px;">
                  <div style="border-bottom: 1px solid; padding-top: 10px; text-align: center; font-size: 14px">
                    {{ document_data.organization_code }}
                  </div>
                  <div style="border-bottom: 1px solid; padding-top: 10px; text-align: center; font-size: 14px">
                    {{ document_data.organization_short_name }}
                  </div>
                  <div style="text-align: center; font-size: 11px">
                    (найменування підприємства/установи/організації)
                  </div>
                </div>
                <div style="font-size: 22px; font-weight: bold; text-align: center; margin-top: 4px; margin-bottom: 4px">
                  Прибутковий касовий ордер № {{ document_data.order_number }}
                </div>
                <div style="text-align: center">від {{ formatDate(document_data.doc_date, 'DD MMMM YYYY') }} року</div>
              </div>
              <div style="flex: 1; margin-left: 4px">
                <div style="display: flex; margin-bottom: 10px">
                  <div style="flex: 0 1 27%"></div>
                  <div style="flex: 1; line-height: 16px">
                    Додаток 2 <br>
                    до Положення про ведення <br>
                    касових операцій у національній <br>
                    валюті в Україні <br>
                    (пункт 25 розділу III)
                  </div>
                </div>
                <div style="text-align: right">Типова форма № КО-1</div>
                <div style="margin-top: 12px">
                  <div style="border-bottom: 1px solid; text-align: left; font-size: 13px">
                    {{ document_data.organization_short_name }}
                  </div>
                  <div style="text-align: center; font-size: 11px">
                    (найменування підприємства/установи/організації)
                  </div>
                </div>
                <div style="font-size: 22px; font-weight: bold; text-align: left; margin-top: 4px;">
                  Квитанція
                </div>
                <div>
                  до прибуткового касового <br>
                  ордеру № {{ document_data.order_number }} <br>
                  від {{ formatDate(document_data.doc_date, 'DD MMMM YYYY') }} року
                </div>
              </div>
            </div>
            <div style="display: flex;">
              <div style="flex: 0 1 64%; margin-right: 4px; border-right: 1px dashed black; padding-right: 8px">
                <div>
                  <table style="width: 100%">
                    <thead>
                      <tr>
                        <th style="width: 50px">№ з/п</th>
                        <th style="width: 113px">Кореспонду-<br>ючий рахунок, субрахунок</th>
                        <th style="width: 105px">Код аналі-<br>тичного рахунку</th>
                        <th style="width: 300px">Сума цифрами</th>
                        <th style="width: 80px">Код цільового призначення</th>
                        <th>Примітки</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                      <td>4</td>
                      <td>5</td>
                      <td>6</td>
                    </tr>
                    <tr style="font-weight: bold">
                      <td>1</td>
                      <td>{{ document_data.credit_code }}</td>
                      <td></td>
                      <td>{{ document_data.sum | formatToFixed | formatNumber }} грн</td>
                      <td></td>
                      <td></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style="flex: 1; margin-left: 4px; display: flex; flex-direction: column; justify-content: flex-end;">
                <div style="font-weight: bold">
                  Прийнято від
                </div>
              </div>
            </div>
            <div style="display: flex;">
              <div style="flex: 0 1 64%; margin-right: 4px; border-right: 1px dashed black; padding-right: 8px">
                <div>
                  <div style="display: flex; margin-top: 2px">
                    <div style="flex: 0 0 110px; font-weight: bold; font-size: 13px">
                      Прийнято від
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ document_data.get_from }}
                    </div>
                  </div>
                  <div style="height: 24px"></div>
                  <div style="display: flex;">
                    <div style="flex: 0 0 110px; font-weight: bold; font-size: 13px">
                      Підстава
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ document_data.reason }}
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 4px">
                    <div style="flex: 0 0 110px; font-weight: bold; font-size: 13px">
                      Сума
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ document_data.converted_number }}
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="flex: 0 0 110px; font-weight: bold; font-size: 13px">
                    </div>
                    <div style="flex: 1; text-align: center">
                      (словами)
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 4px">
                    <div style="flex: 0 0 140px; font-weight: bold; font-size: 13px">
                      в тому числі ПДВ
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      0,00 грн
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 4px">
                    <div style="flex: 0 0 72px; font-weight: bold; font-size: 13px">
                      додатки:
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ document_data.additional }}
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 15px">
                    <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px">
                      Головний бухгалтер
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ chief_accountant }}
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px">
                    </div>
                    <div style="flex: 1; text-align: center">
                      (підпис, прізвище, ініціали)
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 4px">
                    <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px">
                      Одержав касир
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px">
                    </div>
                    <div style="flex: 1; text-align: center">
                      (підпис, прізвище, ініціали)
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex: 1; margin-left: 4px">
                <div style="border-bottom: 1px solid black; height: 21px; margin-top: 2px">
                  {{ document_data.get_from }}
                </div>
                <div style="margin-top: 5px; font-weight: bold">Підстава</div>
                <div style="border-bottom: 1px solid black; height: 21px;">
                  {{ document_data.reason }}
                </div>
                <div style="display: flex; margin-top: 4px">
                  <div style="flex: 0 0 50px; font-weight: bold; font-size: 13px">
                    Сума
                  </div>
                  <div style="flex: 1; border-bottom: 1px solid black">
                    {{ document_data.converted_number }}
                  </div>
                </div>
                <div style="display: flex;">
                  <div style="flex: 0 0 50px; font-weight: bold; font-size: 13px">
                  </div>
                  <div style="flex: 1; text-align: center">
                    (словами)
                  </div>
                </div>
                <div style="margin-top: 4px">В т.ч ПДВ: 0.00 грн.</div>
                <div style="margin-top: 4px">М.П.</div>
                <div style="display: flex; margin-top: 0">
                  <div style="flex: 0 0 80px; font-weight: bold; font-size: 12px">
                    Головний бухгалтер
                  </div>
                  <div style="flex: 1; border-bottom: 1px solid black; align-self: end">
                    {{ chief_accountant }}
                  </div>
                </div>
                <div style="display: flex;">
                  <div style="flex: 0 0 80px; font-weight: bold; font-size: 13px">
                  </div>
                  <div style="flex: 1; text-align: center">
                    (підпис, прізвище, ініціали)
                  </div>
                </div>
                <div style="display: flex; margin-top: 4px">
                  <div style="flex: 0 0 80px; font-weight: bold; font-size: 12px">
                    Касир
                  </div>
                  <div style="flex: 1; border-bottom: 1px solid black">
                  </div>
                </div>
                <div style="display: flex;">
                  <div style="flex: 0 0 80px; font-weight: bold; font-size: 12px">
                  </div>
                  <div style="flex: 1; text-align: center">
                    (підпис, прізвище, ініціали)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {convertNumberToLetterString} from "@/utils/accounting";
import documentPrintAPI from "@/utils/axios/accounting/documents/cash_order_income"
import {formatDate} from "@/filters";
import {endOfMonth} from "@/utils/icons";

export default {
  name: "bill_outcome_v1",
  props: {
    organization: {
      type: Object,
      default() {
        return {}
      }
    },
    print_data: {
      type: Object,
      default() {
        return {}
      }
    },
    document_id: {
      type: Number,
      default: null
    },
    global_settings: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    },
    chief_accountant() {
      return (((this.print_data.response_people || []).find(i => i.category === 'chief_accountant')) || {}).short_name || ''
    }
  },
  data() {
    return {
      setting_dialog: this.global_settings,
      settings_watcher: null,
      document_setting: {
        duplicate_on_differance_page: false
      },
      document_data: {}
    }
  },
  methods: {
    duplicateOnOnePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_differance_page = false
      }
    },
    duplicateOnDifferancePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_one_page = false
      }
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    formatDate,
    getResponsePerson(table, category, field='short_name') {
      if (table === 'organization') {
        return ((this.print_data?.response_people || []).find(i => i.category === category) || {})?.[field] || ''
      } else {
        return (this.document_data?.contractor_print_data || {})?.response_persons?.[category]?.[field] || ''
      }
    },
    getBalanceTitle() {
      const end_month = endOfMonth(this.document_data.month)
      const date = this.document_setting.balance_end ? end_month : this.document_data.month
      const balance = this.document_setting.balance_end
          ? this.document_data.balance_end : this.document_data.balance_start
      let balance_title = ''
      if (balance < 0) {
        balance_title = 'Переплата станом на'
      } else {
        balance_title = 'Заборгованість станом на'
      }

      return `${balance_title} ${formatDate(date, 'DD.MM.YYYY')}`
    },
    convertNumberToString(payload) {
      return convertNumberToLetterString(payload)
    }
  },
  watch: {
    modal: {
      immediate: true,
      handler(payload) {
        if (payload) {
          documentPrintAPI.get_document_for_print(this.document_id)
              .then(response => response.data)
              .then(data => this.document_data = data)
        } else {
          this.document_data = {}
        }
      }
    }
  },
  created() {
    this.watch_settings()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.document-print-form {
  width: 100%;
  font-family: Arial, Calibri, serif;
  padding: 0;
  font-size: 12px;


  .document-table-form {
    width: 100%;
    border-collapse: collapse;
    table {
      border-collapse: collapse;
    }

    thead {
      tr {
        border: 1px solid grey;

        th {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .74rem;
          font-weight: normal;
          line-height: 1.05rem;
        }
      }
    }

    tbody {
      tr {
        border: 1px solid grey;

        td {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .84rem;
          line-height: .94rem;
          text-align: center;
        }
      }
    }
  }

}
</style>